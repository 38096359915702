
<template>
    <div class="modal fade" ref="modalRef" :id="this.identifier" tabindex="-1" :aria-labelledby="this.identifier"
        aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="this.identifier">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <p class="fs-5 text-secondary mt-3">{{ headerText }} </p>


                    <form class="row g-3 mb-4" ref="documentForm">

                        <div class="col-12">
                            <label for="companyName" class="form-label pe-2">Załącznik: </label>
                            <button type="button" class="btn btn-primary truncate mw-100"
                                @click="this.$refs.filePicker.click()">
                                {{ fileButtonText }}
                            </button>

                            <input v-if="isScan" id="file-input" type="file" name="file" style="display: none;"
                                ref="filePicker" accept="image/*" capture="camera" @change="fileSelected" />
                            <input v-else id="file-input" type="file" name="file" style="display: none;" ref="filePicker"
                                @change="fileSelected" />
                        </div>

                        <div class="col-12">
                            <label for="companyName" class="form-label">Nazwa dokumentu</label>
                            <input name="name" type="text" class="form-control" id="companyName" placeholder="Document 1"
                                v-model="this.name">
                        </div>

                        <div class="col-12">
                            <label for="typeNumber" class="form-label">Kwota brutto</label>
                            <input name="checkout" type="number" class="form-control" id="typeNumber" placeholder=""
                                @focusout="checkout = parseFloat(checkout).toFixed(2)" v-model="this.checkout">
                        </div>

                        <div class="col-12">
                            <label for="typeNumber" class="form-label">Data</label>
                            <input name="date" type="date" class="form-control" id="typeNumber" placeholder=""
                                v-model="this.date">
                        </div>

                        <input type="text" name="isScan" :value="isScan" style="display: none;">

                    </form>
                    <div class="col-md-12 mb-3 text-center text-success">
                        <div>{{ this.messageSuccess }}</div>
                    </div>
                    <div class="col-md-12 mb-3 text-center text-danger">
                        <div>{{ this.messageError }}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="closeButton">
                        Zamknij
                    </button>
                    <button type="button" class="btn btn-primary" @click="save" :disabled="isButtonDisabled">Dodaj</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { ref } from 'vue';
import { useSelectedDateStore } from "@/stores/date"
import { storeToRefs } from "pinia"
import { useDocumentStore } from "@/stores/documents";
export default {
    name: "AddDocumentModal",
    mixins: [],
    props: {
        identifier: String,
        isScan: Boolean
    },
    setup() {
        const modalRef = ref(null);
        const dateStore = useSelectedDateStore();
        const documents = useDocumentStore();
        const { firstDayOfMonthAsString } = storeToRefs(dateStore);
        return { modalRef, dateStore, firstDayOfMonthAsString, documents }
    },
    watch: {
        firstDayOfMonthAsString() {
            this.date = this.firstDayOfMonthAsString;
        }
    },
    mounted() {
        this.modal = new Modal(this.modalRef);
    },
    data() {
        return {
            checkout: this.isScan ? "0.00" : "",
            date: this.dateStore.firstDayOfMonthAsString,
            name: this.isScan ? "Skan" : "",
            attachmentPath: "",
            messageSuccess: '',
            messageError: '',
            processing: false,
            modal: null
        }
    },
    methods: {
        async save() {
            this.processing = true;
            this.messageError = '';
            this.messageSuccess = 'Przetwarzanie...';

            const data = new FormData(this.$refs.documentForm);
            const res = await this.documents.addOne(data);

            if (res.status != 200) {
                this.messageSuccess = '';
                this.processing = false;
                this.messageError = "Nieznany błąd";
                if (res.status === 413) {
                    this.messageError = "Limit rozmiaru pliku wynosi 16MB"
                }
                if (res.status === 400) {
                    if (res.data.errorcode === 604) {
                        this.messageError = "Limit miejsca na załączniki został osiągnięty, wykonaj archiwizację";
                    }
                }
                return;
            }

            this.messageSuccess = ""
            this.checkout = this.isScan ? "0.00" : "";
            this.name = this.isScan ? "Skan" : "";
            this.attachmentPath = "";
            this.$refs.closeButton.click();
            this.processing = false;
        },
        fileSelected() {
            this.attachmentPath = this.$refs.filePicker.value;
            if (this.isScan && this.attachmentPath.trim()) {
                this.modal.show();
            }
        },
        startCamera() {
            this.$refs.filePicker.value = "";
            this.$refs.filePicker.click();
        }

    },
    computed: {
        isButtonDisabled() {
            if (this.processing === true) {
                return true;
            }

            const value =
                this.attachmentPath.length === 0 ||
                this.date.length === 0 ||
                this.checkout.length === 0 ||
                this.name.length === 0;

            return value;
        },
        fileButtonText() {
            let text = "Prześlij";

            if (this.attachmentPath.length !== 0) {
                const sections = this.attachmentPath.split('\\');
                const name = sections.at(sections.length - 1);
                return name;
            }

            return text;
        },


        headerText() {
            let str = "Informacje nt.";
            if (this.isScan == true)
                return `${str} skanu`;

            if (this.isScan == false)
                return `${str} dokumentu`;

            return "";
        },

        title() {
            if (this.isScan) {
                return "Skanuj koszt";
            }
            return "Dodaj koszt";
        }
    }

};
</script>
