<template>
  <div class="modal fade" :id="this.identifier" tabindex="-1" :aria-labelledby="this.identifier" aria-hidden="true"
    ref="mainDiv">
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="this.identifier">Dodaj koszt cykliczny</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="fs-5 text-secondary">Ustawienie płatności</p>

          <form class="row g-3" ref="documentForm">
            <div class="col-12">
              <label for="companyName" class="form-label">Nazwa płatności</label>
              <input type="text" class="form-control" id="companyName" placeholder="Zus" v-model="name" name="name" />
            </div>

            <div class="col-12">
              <label for="companyName" class="form-label">Cykl płatności od dnia</label>
              <input id="startDate" class="form-control" type="date" v-model="dateStarts" name="dateStarts" />
              <span id="startDateSelected"></span>
            </div>

            <div class="col-12">
              <select class="form-select" aria-label="Default select example" v-model="repeats" name="repeats"
                placeholder="wybierz interwał płatności">
                <option value="6">Tylko raz</option>
                <option value="1">Codziennie</option>
                <option value="2">Co tydzień w {{ dayNames[dateStartsOBJ.getDay()] }}</option>
                <option value="3">Co miesiąc {{ dateStartsOBJ.getDate() }} dnia</option>
                <option value="5">Co kwartał w dniu {{ dateStartsOBJ.getDate() }}</option>
                <option value="4">Co rok w dniu {{ dateStartsOBJ.getDate() }} {{
                  monthNames[this.dateStartsOBJ.getMonth()] }}</option>
              </select>
            </div>

            <input id="file-input" type="file" name="file" style="display: none;" ref="filePicker"
              @change="fileSelected" />

            <ContractorPicker :id="contractorId" @contractor-changed="contractorPicked" v-if="this.isShown"
              @message-adding-contractor="openedaddContractor" ref="picker">
            </ContractorPicker>

            <div class="col-md-6">
              <label for="inputCity" class="form-label">Kwota brutto</label>
              <div class="input-group">
                <input type="number" class="form-control" id="kwota" v-model="checkout" name="checkout"
                  @focusout="checkout = parseFloat(checkout).toFixed(2).toString()">
                <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                  @click="copy(this.checkout)" id="button-addon2"><span
                    class="material-symbols-outlined">content_copy</span></button>
              </div>
            </div>
            <input type="text" name="contractorId" :value="contractorId" style="display: none;">
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="closeButton">
            Zamknij
          </button>
          <button type="button" class="btn btn-primary" @click="saveBtn" :disabled="isButtonDisabled">Dodaj</button>
        </div>
      </div>
    </div>
  </div>

  <button type="button" style="display: none;" data-bs-toggle="modal" :data-bs-target="'#' + identifier"
    ref="openMeButton">
  </button>
</template>

<script>
import { useContractorsStore } from '@/stores/contractors';
import ContractorPicker from '../listing/contractorPicker.vue';
import { MONTH_NAMES_MIXIN } from '@/mixins/monthNames';
import { useSelectedDateStore } from "@/stores/date"
import { usePeriodicsStore } from '@/stores/periodics'
import { storeToRefs } from "pinia"
export default {
  name: "AddCostModal",
  components: { ContractorPicker },
  mixins: [MONTH_NAMES_MIXIN],
  props: {
    identifier: String,
    newlyAddedContractor: Object
  },
  setup() {
    const dateStore = useSelectedDateStore();
    const periodics = usePeriodicsStore();
    const contractors = useContractorsStore();
    const { firstDayOfMonthAsString } = storeToRefs(dateStore);
    return { dateStore, firstDayOfMonthAsString, periodics, contractors }
  },
  mounted() {
    this.dateStarts = this.dateStore.firstDayOfMonthAsString;
    const that = this;
    this.$refs.mainDiv.addEventListener("hidden.bs.modal", function () {
      that.$emit('closed');
    });
    this.$refs.mainDiv.addEventListener("shown.bs.modal", function () {
      that.$emit('opened');
    })
  },
  data() {
    return {
      dayNames: ['Niedzelę', 'Poniedziałek', 'Wtorek', 'Środę', 'Czwartek', 'Piątek', 'Sobotę'],
      checkout: '',
      name: '',
      dateStarts: '',
      repeats: 3,
      attachmentPath: '',
      modalWasActivated: false,
      processing: false,
      isShown: true,

      contractorId: -1,
      contractor: {
        address1: '',
        address2: '',
        bankAccountNumber: '',
        email: '',
        name: '',
        nip: ''
      },
    }
  },
  computed: {
    isButtonDisabled() {
      if (this.processing === true) {
        return true;
      }
      const value =
        !this.checkout ||
        !this.name ||
        !this.dateStarts ||
        !this.repeats ||
        this.contractorId === -1;

      return value;
    },
    fileButtonText() {
      let text = "Prześlij";

      if (this.attachmentPath.length !== 0) {
        const sections = this.attachmentPath.split('\\');
        const name = sections.at(sections.length - 1);
        return name;
      }

      return text;
    },
    dateStartsISO() {
      return this.dateStartsOBJ.toISOString();
    },
    dateStartsOBJ() {
      return new Date(this.dateStarts);
    }
  },
  methods: {
    openedaddContractor() {
      this.modalWasActivated = true;
      sessionStorage.setItem('contractorCalledFrom', 'costModal');
    },
    async saveBtn() {
      this.processing = true;
      const data = new FormData(this.$refs.documentForm);
      data.set('dateStarts', this.dateStartsISO);

      const status = await this.periodics.addOne(this.contractorId, this.dateStarts, this.repeats, this.name, this.checkout);

      if (status !== 200) {
        this.pushError("Zapisywanie danych płatności okresowej nie powiodło się");
        return;
      }
      this.dateStarts = this.dateStore.firstDayOfMonthAsString;
      this.checkout = '';
      this.name = '';
      this.$refs.closeButton.click();
      this.processing = false;
    },
    open() {
      if (this.modalWasActivated) {
        this.$refs.openMeButton.click();
      }
    },
    contractorPicked(data) {
      this.contractorId = data.id;
    },
    fileSelected() {
      this.attachmentPath = this.$refs.filePicker.value;
    },
  },
  watch: {
    firstDayOfMonthAsString() {
      this.dateStarts = this.firstDayOfMonthAsString;
    },
    async newlyAddedContractor(data) {

      if (data && this.modalWasActivated) {
        this.contractorId = data.id;
      }

    }
  }
};
</script>
